var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ElDropdownItem',{class:[
    'el-dropdown-menu__item',
    _vm.newDesign && 'el-dropdown-menu__item--new-design',
    _vm.disabled && 'is-disabled',
    _vm.divided && 'el-dropdown-menu__item--divided',
    _vm.icon && 'el-dropdown-menu__item--with-icon',
  ],attrs:{"aria-disabled":_vm.disabled,"tabindex":_vm.disabled ? null : -1,"command":_vm.command}},[(_vm.icon)?_c('i',{class:['icon ' + 'icon-' + _vm.icon]}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"el-dropdown-menu__content"},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }